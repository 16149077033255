import {Button, FormControl, TextField} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object, string} from "prop-types"

import {useSession} from "contexts/session/session-context"
import {matchesPassword, validPassword} from "lib/field-validations"
import {useForm} from "lib/hooks/use-form"

import CenteredBox from "../centered-box/centered-box"
import DocumentTitle from "../document-title/document-title"

const ResetPassword = ({classes, onSubmit, serverError}) => {
  const {
    team: {passwordPolicy},
  } = useSession()

  const {field, handleSubmit} = useForm({
    enableReinitialize: true,
    validators: {
      password: [validPassword(passwordPolicy)],
      passwordConfirmation: [matchesPassword],
    },
    onSubmit,
  })
  const password = field("password")

  return (
    <CenteredBox errorMessage={serverError} title="Reset Your Password">
      <DocumentTitle title="Reset Your Password" />
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth={true} margin="normal">
          <TextField
            label="New Password"
            type="password"
            {...password}
            helperText={
              password.helperText ? (
                <ul style={{paddingLeft: 16}}>
                  {password.helperText.map(helperText => (
                    <li key={helperText}>{helperText}</li>
                  ))}
                </ul>
              ) : null
            }
          />
        </FormControl>
        <FormControl fullWidth={true} margin="normal">
          <TextField
            label="Repeat Password to Confirm"
            type="password"
            {...field("passwordConfirmation")}
          />
        </FormControl>
        <FormControl className={classes.actions} fullWidth={true} margin="normal">
          <Button color="primary" type="submit" variant="contained">
            Reset My Password
          </Button>
        </FormControl>
      </form>
    </CenteredBox>
  )
}

const styles = {
  actions: {
    display: "block",
    marginTop: 20,
    textAlign: "right",
  },
}

ResetPassword.propTypes = {
  classes: object.isRequired,
  onSubmit: func.isRequired,
  serverError: string,
}

export default withStyles(styles)(ResetPassword)
